.table-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.row-white {
    /* background-color: white !important; */
    background-color: white !important;
}

.row-dull {
    /* background-color: rgba(209, 209, 209, 0.522) !important; */
    background-color: #FDC3D1;
    color: grey;
}
.row-dull:hover {
    /* background-color: rgba(209, 209, 209, 0.522) !important; */
    background-color: #FDC3D1 !important;
    color: grey;
}
.row-yellow
{
    background-color: rgba(253, 243, 213, 0.522) !important;


}

/* Big screens (1025px and above) */
@media (min-width: 1025px) {
    /* .table-container {
        width: 77vw;
    } */
}

/* Tablets (768px to 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
    /* .table-container {
        width: 65vw;
    } */
}

/* Mobile devices (up to 767px) */
@media (max-width: 767px) {
    /* .table-container {
        width: 88vw;
    } */
}
.login-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.side-container {
    width: 25%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    width: 100%;
    position: absolute;
    top: 5%;
    display: flex;
    justify-content: center;
    left: 0%;
}

.logo img {
    width: 50%;
    height: 50%;
}

.side-container-text {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0%;
    text-align: center;
}

.side-container-chip {
    position: absolute;
    bottom: 5%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.side-content img {
    width: 100%;
    height: 150px;
}

.main-container {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.main-container-content {
    width: 60%;
}

.loginform-password-conten {
    display: flex;
    justify-content: space-between;
}

.contact-admin {
    position: absolute;
    top: 5%;
    right: 5%;

}

.privacy-policy {
    position: absolute;
    bottom: 5%;
    right: 5%;
}

.changepassword-note {
    border-radius: 20px;
    background-color: #F3F6FF;
    padding: 20px;
}

/* Big screens (1025px and above) */
@media (min-width: 1025px) {}

/* Tablets (768px to 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {}

/* Mobile devices (up to 767px) */
@media (max-width: 767px) {
    .side-container {
        display: none !important;
    }

    .main-container-content {
        width: 100%;
    }
}
.container-filemanager {
    background-color: transparent;
    margin-top: 12px;
    border-radius: 30px;
    padding: 30px;
    height: 79vh;
    overflow-y: scroll;
  }

  .filename-icon
  {
    width: 25px;
    height: 25px;
  }

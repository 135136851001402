.sidebar {
    position: relative;
}

.sidebar-list {
    position: relative;
    /* position: absolute;
    top: 40%;
    transform: translateY(-50%); */

}

.sidebar-list-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 60vh;
    overflow-y: scroll;
}

.sidebar-footer {
    position: absolute;
    bottom: 7%;
    left: 0%;
    width: 100%;
    transform: translateY(50%);
}

.sidebar-adduser {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    color: #6A4BFF;

}

.toolbar-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.toolbar-profile {
    border-radius: 26px;
    background-color: #15539E;
    display: flex;
    align-items: center;
    justify-content: center;
}

.list-item-active {
    color: #15539E !important;
    fill: #15539E !important;
    background-color: white !important;
    border-radius: 26px !important;
}

.list-item-active svg {
    color: #15539E !important;
    fill: #15539E !important;
}

.chat-unseen-num {
    background-color: #15539E !important;
    padding: 2px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
}
.chat-unseen-num:hover {
    background-color: #15539E !important;
    padding: 2px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
}
.white
{
    background-color: white !important;
    color: #15539E;


}
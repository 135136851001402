/* Drawer styles */
.drawer {
    position: absolute;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background-color: #2F4C6B;
    color: white;
    transition: left 0.9s ease;
    padding: 20px;
    box-shadow: 2px 0px 5px #2F4C6B;
    /* background-color: #1a1a1a; */
  }
  
  .drawer.open {
    left: 0;
  }
  
  .drawer-toggle-btn {
    margin: 10px;
    padding: 10px 20px;
    color: grey;
    border: none;
    cursor: pointer;
  }
  
  .drawer-toggle-btn:hover {
    background-color: rgba(128, 128, 128,0.3);
    color: black;
    border-radius: 50%;
  }
  
  .close-btn {
    background-color: transparent;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    float: right;
  }
  
  .close-btn:hover {
    background-color: grey;
    border-radius: 50%;
  }
  
  
  
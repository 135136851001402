.confirm-btn {
    font-weight: 400;
    font-size: 20px;
    font-family: Poppins;
    line-height: 34.5px;
    width: 100%;
    margin: 10px;
    text-align: center;
    color: white;
    border-radius: 25px;

}

.delete {
    background: rgba(19, 85, 155, 1);

}

.cancel {
    background: rgba(236, 50, 57, 1);

}
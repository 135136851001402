body {
    background-color: #F6FAFF !important;
    /* display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; */
    position: relative;
    height: 100vh;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #15539E;
    border-radius: 25px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}


/* -----------testing-------------- */
.parent-div{
    background-color: cadetblue;
    width: 50vw;
    height: 50vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.mailProfile
{
    width: 30px;
    height: 30px;
    border-radius: 50%;


}

.autocomplete
{
    width: 30%;
}

/* Big screens (1025px and above) */
@media (min-width: 1025px) {
    .autocomplete
    {
        width: 30%;
    }
}

/* Tablets (768px to 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
   .autocomplete
   {
    width: 50%;
   }
}

/* Mobile devices (up to 767px) */
@media (max-width: 767px) {
    /* .table-container {
        width: 88vw;
    } */
    .autocomplete
    {
        width: 100%;
    }
}
.setting-card {
  height: 126px;
  width: 100%;
  background-color: white;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 20px;
  padding: 12px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.setting-card-left {
  position: relative;
  width: 60%;
}

.setting-card-link-sec {
  position: absolute;
  bottom: 3%;
  display: flex;
  /* justify-content: space-between; */
}

.setting-card-right {
  width: 40%;
}

.setting-per {
  border: 3px solid #15539E;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  background-color: white;
  margin-top: 12px;
  border-radius: 30px;
  padding: 30px;
  height: 79vh;
  overflow-y: scroll;
}
.table-container {
  background-color: white;
  margin-top: 12px;
  height: 75vh;
  overflow-y: hidden;
}

.form-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.form-icon:hover {
  background-color: gray !important;
}
.agent-logo
{
  width: 101px;
  height: 101px;
  border-radius: 50%;
  background-color: #6a4bff;
}
.addcatalog-container
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  
}

.underline-hover {
  display: inline-block;
  position: relative;
  text-decoration: none; /* Remove default underline */
  cursor: pointer;
}

.underline-hover::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px; /* Height of the underline */
  bottom: 0;
  left: 0;
  background-color: #EC3239; /* Underline color */
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.underline-hover:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

#receiver
{
  background: #C8BDFF3D;
  padding: 20px;
  border-radius: 9px;
}
#sender
{
  background: #EFF6FF;
  padding: 20px;
  border-radius: 9px;


}

/* Big screens (1025px and above) */
@media (min-width: 1025px) {}

/* Tablets (768px to 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {}

/* Mobile devices (up to 767px) */
@media (max-width: 767px) {}
.my-dropzone {
  background-color: rgba(228, 235, 255, 1) !important;
  border-radius: 10px !important;

  height: 200px !important;
  border: 2px dotted black !important;
}
.my-dropzone-content {
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  height: 100%;
  padding: 10px;
}
.my-logodropzone-logo
{
    width: 140px;
    height: 140px;
    border-radius: 50%;
    border: 2px dotted black;
}
.my-dropzone-container
{
  width:100%;
  border: 2px dotted #A38D8D;
  border-radius: 10px;
  padding: 10px;
  /* height: 181px; */
  background-color: #E4EBFF;

}
.my-logodropzone-container
{
  width: 80%;
  border-radius: 10px;
  padding: 10px;

}